import { fetchGet ,fetchPut ,fetchPost, fetchDelete} from   "../../router/https.js"
function orderInfo(data){ 
    return fetchGet('/createrapp/makerorderinfo/page',data)
}

function orderDetail(data){ 
    return fetchGet('/createrapp/makerorderinfo/'+data)
}

function edit(data){ 
    return fetchPut('/createrapp/makerorderinfo',data)
}

function delOrder(data){ 
    return fetchDelete('/createrapp/makerorderinfo/'+data)
}



function getAddress(){ 
    return fetchGet('/mallapp/useraddress/page')
}

function upOrder(data){ 
    return fetchPost('/createrapp/makerorderinfo/orderSub',data)
}

function getReason(data){ 
    return fetchGet('/createrapp/makerorderrefunds/getReason',data)
}

function postRefund(data){ 
    return fetchPost('/createrapp/makerorderrefunds',data)
}

function upLoad(data){ 
    return fetchPost('/createrapp/makerorderrefunds/uploadFile',data)
}

function getVoucher(data){ 
    return fetchGet('/createrapp/makerorderrefunds/uploadVoucher',data)
}
function getLogisticsList(data){ 
    return fetchGet('/createrapp/makerorderrefunds/getAllCompany',data)
}
function afterSale(data){ 

    return fetchGet('/createrapp/makerorderrefunds/page',data)
}

function makerGetLog(data){ 
    return fetchGet('/createrapp/makerorderinfo/getLogisticsInfo',data)
}

function getRefundDetail(data){ 
    return fetchGet('/createrapp/makerorderrefunds/'+data)
}

function getFreight(data){ 

    return fetchPost('/createrapp/makerorderinfo/orderView',data)
    
}
export { 
    //创客订单列表
    orderInfo,
    //创客订单详情
    orderDetail,
    //修改订单状态
    edit,
    //删除订单
    delOrder,
 

    //获取收货地址
    getAddress,
    // 创客提交订单
    upOrder,
    //获取退款原因
    getReason,
    //创建退货申请
    postRefund,
    //文件上传
    upLoad,
    //上传退款凭证接口
    getVoucher,
    //获取物流接口
    getLogisticsList,
    //售后列表
    afterSale,

    //创客物流信息
    makerGetLog,
    // 获取退款详情
    getRefundDetail,
    //获取商品运费
    getFreight
}