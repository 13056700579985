<template>
  <div class="page" v-if="refund!=null">
     <Head :type="0"> 
            <template #title>
               <div class="title" v-if="refund.status=='refundsuccess'">已退款</div>

      <div class="title" v-if="refund.status=='SELLER_REJECT_REFUND'">退款失败</div>

      <div class="title" v-if="refund.status!='refundsuccess'&&refund.status!='SELLER_REJECT_REFUND'">退款中</div>
            </template>
        </Head>
   
    <div class="main">
      <div class="jindu">
        <div class="icon">
          <img src="../../../assets/check.png" alt="" />
          <p></p>
          <img src="../../../assets/check.png" alt="" />
          <p></p>

            <img src="../../../assets/check.png" alt=""  v-if="refund.status=='refundsuccess'" />
           
           <img src="../../../assets/img/refundclose.png" alt="" v-if="refund.status=='SELLER_REJECT_REFUND'"/>
           <img v-if="refund.status!='refundsuccess'&&refund.status!='SELLER_REJECT_REFUND'" src="../../../assets/checkbefore.png" alt="" />
          <p></p>

          <img src="../../../assets/check.png"  alt=""  v-if="refund.status=='refundsuccess'"  />
           
           <img v-else src="../../../assets/checkbefore.png" alt="" />
          <p></p>
 <img src="../../../assets/check.png"  alt=""  v-if="refund.status=='refundsuccess'"  />
          <img v-else src="../../../assets/checkbefore.png" alt="" />
        </div>
        <div class="word">
          <span>申请退款</span>
          <span>&ensp;处理中&ensp;</span>
          <span>财务退款</span>
          <span>银行处理</span>
          <span>&ensp;已到账</span>
        </div>
      </div>
      <div class="process">
        <van-steps
          direction="vertical"
          :active="0"
          active-color="#333333"
          inactive-color="#999"
        >
         <van-step v-if="refund.status=='refundsuccess'">
            <h3>您的退款Win生活已受理完成。到账周期可查看退</h3>
            <p>{{refund.updateTime}}</p>
          </van-step>
        <van-step v-if="refund.status=='refundsuccess'">
            <h3>商家同意退款</h3>
            <p>{{refund.updateTime}}</p>
          </van-step>
        <van-step v-if="refund.status=='SELLER_REJECT_REFUND'">
            <h3>您的退款已被商家拒绝:{{refund.refundReson}}</h3>
            <p>{{refund.updateTime}}</p>
          </van-step>
          <van-step>
            <h3>您的订单拦截成功，正在进行退款审核，请耐心等待。</h3>
            <p>{{refund.createTime}}</p>
          </van-step>
          <van-step>
            <h3>商家已收到您的退款申请，正在等待商家审核。</h3>
            <p>{{refund.createTime}}</p>
          </van-step>
          <van-step>
            <h3>您的退款申请已提交。</h3>
            <p>{{refund.createTime}}</p>
          </van-step>
          <template #active-icon>
            <div class="area"></div>
          </template>
        </van-steps>
      </div>
      <div class="info">
        {{data}}
          <div class="title">退款明细</div>
          <div class="subtitle">¥{{refund.refundAmount}}（1-3个工作日到账）</div>
          <div class="desc">退款将退还您实际支付的金额，支付宝、微信支付、等资产将原路返还。</div>
      </div>
      <div class="bottom">
          <div class="title">基本信息</div>
          <div class="list"><span class="right">联系方式</span> <span class="content" v-if="$store.state.userDate.phone">{{$store.state.userDate.phone}}</span> </div>
          <div class="list"><span class="right">订单编号</span> <div class=" van-ellipsis" >  {{refund.orderNo}}</div > </div>

      </div>
    </div>
  </div>
</template>

<script>
import{ getRefundDetail} from '../../../api/maker/order'
import Head from '../../../components/head/index.vue'
export default {
  data() {
    return {
      active: 1,
      refund:null,
      data:null
    };
  },
  created() {
     
        try{ 
        window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({img:'http://121.36.73.162:9000/joolun/1/material/c44973ad-ea8b-4dbd-9bfc-8f82d7c9a45d.png'}))

        }catch{ 
          //
        }

      getRefundDetail(this.$route.query.orderNo).then(res=>{ 
        if(res.data.code==0){ 
          this.refund=res.data.data
        }
      })
    this.getUser()
  
  },
  components:{Head},
  mounted() {
    this.setRem();
  },
  beforeDestroy(){
    
    try{ 
      window.webkit.messageHandlers.statusBar.postMessage(JSON.stringify({}))

    }catch{ 
      //
    }

  }
};
</script>

<style lang="less" scoped>
// .container {
//   // padding-top: constant(safe-area-inset-top);
//   // padding-right: constant(safe-area-inset-right);
//   // padding-bottom: constant(safe-area-inset-bottom);
//   // padding-left: constant(safe-area-inset-left);
// }
.bg {
  height: 6.706667rem;
}
.page {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  font-family: PingFangSC-Semibold, PingFang SC;
  flex-direction: column;
  background-color: #f6f6f6;
  background-image: url("../../../assets/img/hotel_bottom.png");
  background-repeat: no-repeat;
  background-size: 100% 5.346667rem;

  .title {
      
      color: white;
    
    }
  .main::-webkit-scrollbar{
      display: none;
  }
  .main {
    height: 100%;
    padding: 0.533333rem 0.426667rem;
    box-sizing: border-box;
   
    overflow: auto;
    .jindu {
      margin-bottom: 0.533333rem;
      height: 1.813333rem;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 0.266667rem;
      padding: 0.373333rem 0.266667rem 0;
      box-sizing: border-box;
      .icon {
        display: flex;
        padding: 0 0.4rem;

        justify-content: space-around;
        align-items: center;
        img {
          width:0.666667rem;
          height: 0.666667rem;
        }
        p {
          width: 0.8rem;
          height: 0.053333rem;
          background-color: white;
        }
      }
      .word {
        font-size: 0.32rem;
        display: flex;
        align-items: center;
        padding-top: 0.133333rem;

        span {
          display: block;
          transform: scale(0.83);
          color: rgba(255, 255, 255, 0.85);
          text-align: center;
          flex: 1;
        }
      }
    }
    .process {
      padding-top: 0.266667rem;
      border-radius: 0.266667rem;
      overflow: hidden;
      margin-bottom: .266667rem;
      .van-steps /deep/ {
        border-radius: 0.266667rem;
        /deep/ h3 {
          font-family: PingFangSC-Regular, PingFang SC !important;
          font-size: 0.373333rem !important;
          font-weight: 400;
          margin-bottom: 0.133333rem;
        }
      }
      .area {
        width: 0.186667rem;
        height: 0.133333rem;
        border: 2px solid #ff5200;
        border-radius: 50%;
      }
      /deep/ .van-step__icon--active {
        width: 0.186667rem;
        height: 0.133333rem;
        border: 2px solid #ff5200;
        border-radius: 50%;
        background: #ffc698;
        display: block !important;
      }
      /deep/.van-icon-checked::before {
        content: "";
      }
      /deep/.van-step__circle {
        display: block !important;
        width: 0.186667rem;
        height: 0.186667rem;
        background: #e0e0e0;
        border: 0.026667rem solid #999999;
      }
    }
    .info{
        background-color: white;
        border-radius: .266667rem;
        padding: .266667rem;
        .title{
            font-weight: 500;
            font-family: PingFangSC-Medium, PingFang SC;
            font-size: .426667rem;
            color: #333333;
            margin-bottom: .266667rem;
        }
        .subtitle{
            font-size: .426667rem;
            font-weight: 500;
            color: #333333;
            margin-bottom: .266667rem;

        }
        .desc{
            font-size: .373333rem;
            font-weight: 400;
            color: #999999;
        }
    }
    .bottom{
        margin-top: .266667rem;
        border-radius: .266667rem;
        background-color: white;
        padding: .266667rem;
        margin-bottom: constant(safe-area-inset-bottom);
        margin-bottom: env(safe-area-inset-bottom);
        box-sizing: border-box;

        .title{
            font-size: .426667rem;
            font-family: PingFangSC-Medium, PingFang SC;
            color: #333333;
            margin-bottom: .266667rem;
        }
        .list{
            display: flex;
            height: 1.493333rem;
            align-items: center;
            font-family: PingFangSC-Regular, PingFang SC;
            box-sizing: border-box;
          span{
              display: flex;
              align-items: center;
          }
          .right{
              margin-right: .56rem;
              color: #999;
              font-size: .453333rem;
              white-space: nowrap;
          }
          .content{
            color: #333333;
            font-size: .453333rem;
            flex: 1;
            box-sizing: border-box;
            width: .666667rem;
          }
        }
    }
  }
}
</style>